import { combineReducers } from "redux";
import auth from "./auth";
import home from "./home";
import service from "./service";

const root = combineReducers({
    auth,
    home,
    service,
});

export default root;