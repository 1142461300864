
//auth
export const LOGIN_RESPONSE= "LOGIN_RESPONSE"
export const  LOGOUT_RESPONSE ="LOGOUT_RESPONSE"
export const FORGOT_PASSWORD_RESPONSE= "FORGOT_PASSWORD_RESPONSE"
export const VERIFY_OTP_RESPONSE= "VERIFY_OTP_RESPONSE"
export const RESET_PASSWORD_RESPONSE= "RESET_PASSWORD_RESPONSE"

//home
export const HOME_DATA_RESPONSE ="HOME_DATA_RESPONSE"
export const ADMIN_LIST_RESPONSE = "ADMIN_LIST_RESPONSE"
export const STYLIST_LIST_RESPONSE ="STYLIST_LIST_RESPONSE"
export const SERVICE_DETAIL_RESPONSE ="SERVICE_DETAIL_RESPONSE"

export const DRIVER_LIST_RESPONSE ="DRIVER_LIST_RESPONSE"
export const DRIVER_DETAIL_RESPONSE ="DRIVER_DETAILS_RESPONSE"
export const DRIVER_SEARCH_RESPONSE ="DRIVER_SEARCH_RESPONSE"


//

//claims
export const CLAIM_LIST_RESPONSE ="CLAIM_LIST_RESPONSE"
export const ACCIDENTAL_CLAIM_LIST_RESPONSE ="ACCIDENTAL_CLAIM_LIST_RESPONSE"
export const CLAIM__DETAILS_LIST_RESPONSE ="CLAIM__DETAILS_LIST_RESPONSE"