import {
    STYLIST_LIST_RESPONSE,
    DRIVER_DETAIL_RESPONSE,

   
} from "../action/type";

const Initial_state = {
    driver_list_data:"",
    driver_detail_data:"",
    driver_search_data:""
}

export default (state = Initial_state, action) => {
    const { type, payload } = action;
    switch (type) {

        case STYLIST_LIST_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    driver_list_data: payload?.data
                } 
            };
        case DRIVER_DETAIL_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    driver_detail_data: payload?.data
                } 
            };
   
         
        default:
            return state;
    } 
}